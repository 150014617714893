
.account-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .del-wrapper {
        margin-bottom: 20px;
        display: flex;

        .del-btn {
            cursor: pointer;
            transition: all .3s;

            i {
                margin-right: 6px;
            }

            &:hover {
                color: #5864e4;
            }
        }

        .del-number {
            margin-left: 20px;
        }
    }

    .account-number {
        display: flex;
        align-items: center;

        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }

        .scope-title {
            max-width: calc(100% - 50px);
            margin-left: 10px;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            cursor: pointer;
            font-size: 14px;
        }
    }

    .down {
        & i {
            font-size: 14px;
            color: #42D7AB;
            -ms-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            padding-right: 10px;
            display: inline-block;
            transform: rotate(180deg);
        }
    }

    .up {
        & i {
            font-size: 14px;
            color: #FF0000;
            padding-left: 10px;
        }
    }

    .operation {
        .topping {
            span {
                color: #4659f2;

                &:hover {
                    color: #463bdc;
                }
            }
        }

        .del {
            span {
                color: #f56c6c;

                &:hover {
                    color: #ff0000;
                }
            }
        }
    }

    .under-passed {
        color: #1BB285;
    }

    .under-err {
        color: #f56c6c;
    }

    .script-content-list {
        .script-content-tab {
            ::v-deep .el-tabs__item {
                height: 45px;
                line-height: 45px;
                font-size: 16px;
                font-weight: 400;
                border: none;
                color: #16113F;
            }

            ::v-deep .el-tabs__active-bar {
                background: #9ACC5B;
            }

            ::v-deep .el-tabs__active-bar:before {
                position: absolute;
                top: -13px;
                left: 40%;
                content: '';
                width: 0;
                height: 0;
                border-style: dashed dashed solid dashed;
                border-width: 7px;
                border-color: transparent transparent #9ACC5B transparent;
            }

            /*::v-deep.el-tabs__item{*/
            /*    padding: 0;*/
            /*    margin-right:20px;*/
            /*    box-sizing: border-box;*/
            /*}*/
        }

        .dy-main {
            display: flex;
            flex-direction: column;
            height: calc(100vh - 286px);

            ::v-deep .el-table {
                display: flex;
                flex-direction: column;
            }

            ::v-deep .el-table__footer-wrapper,
            ::v-deep .el-table__header-wrapper {
                overflow: initial;
            }

            ::v-deep .el-table__body-wrapper {
                overflow: auto;
            }
        }
    }
}
.box_btn{
    width: 100%;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // background: skyblue;
    .text{
        display: inline-block;
        max-width: 90%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .editBtn{
        cursor: pointer;
        display: none;
        margin-left: 10px;
    }
  &:hover{
    .editBtn{
        cursor: pointer;
        display: inline-block;
    }
}
}


.re_bind_douyin {
    width: 60px;
    height: 22px;
    background: #F2F2F4;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(37, 38, 50, 0.6);
    border: none;
    margin-left: 10px;
}
::v-deep .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
    // border-bottom: 1px solid transparent !important;
}
::v-deep .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
    // border-right: 1px solid #c0c4cc !important;
}
::v-deep .el-table .caret-wrapper {
    display: none !important;
}
::v-deep .el-table--medium .el-table__cell{
    padding: 8px 0px !important;
}
::v-deep .el-table .caret-wrapper-c{
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 34px;
    width: 24px;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
}
/* 设置滚动条整体 */
::-webkit-scrollbar {
  width: 4px; /* 滚动条宽度 */
  height: 4px; /* 水平滚动条高度 */
}

/* 设置滚动条轨道 */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* 轨道背景色 */
}

/* 设置滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: #888; /* 滑块背景色 */
  border-radius: 10px; /* 滑块圆角 */
}

/* 设置滚动条滑块悬停时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* 滑块悬停背景色 */
}
