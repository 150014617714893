
::v-deep .el-tab-pane {
    height: 100%;
}

.account-wrpper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);

    ::v-deep .el-tabs {
        flex: 1;
        box-shadow: none;
        border: none;
        background: transparent;
    }

    ::v-deep .el-tabs__header {
        border-bottom: none;
        margin: 0;
    }

    ::v-deep .el-tabs__content {
        height: 1%;
        flex: 1;
        background: #fff;
        padding-top: 20px;
    }

    .account-item {
        width: 100%;
        height: calc(100% - 40px);
        overflow: auto;
        display: flex;
        flex-wrap: wrap;

        & li {
            display: flex;
            align-items: center;
            justify-content: start;
            flex-wrap: wrap;
            cursor: pointer;
        }

        .account-img {
            display: inline-block;
            height: 60px;
            margin: 0 20px 20px 0;

            img {
                border-radius: 50%;
                width: 48px;
                height: 48px;
            }
        }
    }

    .tooltip-icon {
        margin-left: 10px;

        &:hover {
            color: #1E34E4;
            cursor: pointer;
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: center;
        align-items: center;

        ::v-deep .el-button--primary {
            color: #FFF;
            background-color: #0824ce;
            border-color: #0824ce;
        }

        ::v-deep .el-button--primary:focus,
        .el-button--primary:hover {
            background: #495df1;
            border-color: #495df1;
            color: #FFF;
        }
    }
}

::v-deep .el-tabs__header .is-active {
    background: #fff;
    font-size: 16px;
    font-weight: 400;
    color: #0824ce;
    border-radius: 6px 6px 0 0;
}

::v-deep .el-tabs__item {
    height: 52px;
    line-height: 52px;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: none;
}

.script-wrapper {
    display: flex;
    flex-direction: column;
    height: 1%;
    flex: 1;
    background: #fff;
    border-radius: 10px;
    padding: 30px 40px 0;

    .account-creation-tabs {
        text-align: left;

        .account-tabs {
            display: inline-block;
            margin-right: 20px;
            position: relative;
            cursor: pointer;
            transition: all .3s;
            background-color: #F1F0FE;
            color: #574DED;
            border-radius: 4px;
            padding: 4px 16px;

            &:last-child {
                margin-right: 0;
            }

            &.current {
                background-color: #574DED;
                color: #fff;
            }

            &:hover {
                background-color: #1E33E4;
                color: #fff;
            }
        }
    }

    .script-item {
        /*display: none;*/
        height: 1%;
        flex: 1;
        justify-content: flex-start;
        margin-top: 20px;

        .script-content {
            padding: 16px;
            border-bottom: 1px solid #EAEAEA;

            .script-search {
                display: flex;

                .el-input {
                    width: 1%;
                    flex: 1;
                    margin-left: 10px;
                }

                ::v-deep .el-input__inner {
                    border: 1px solid #594FEE;
                    border-radius: 15px;
                }
            }

            .script-model {
                text-align: center;
                margin-bottom: 16px;
            }

            .hot-script {
                text-align: left;
                margin: 16px 0 0;
            }
        }

        .script-list {
            height: 1%;
            flex: 1;
            margin: 10px 0;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .item {
                padding: 16px;
                border-bottom: 1px solid #EAEAEA;

                .script {
                    margin-bottom: 10px;
                    color: #333;

                    &:hover {
                        color: #1E33E3;
                    }
                }

                .script-btn {
                    text-align: right;

                    .script-btn-blue {
                        border-radius: 4px;
                        background: #574DED;
                        color: #fff;

                        &:hover {
                            background: #1E33E3;
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                }

                .item-top {
                    display: flex;

                    .script-img {
                        width: 93px;
                        height: 58px;
                        display: inline-block;

                        margin-right: 10px;
                    }
                }
            }
        }

        &.current {
            display: flex;
        }

        .script-item {
            flex: 1;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
    }

    .account-btn-box {
        display: flex;
        justify-content: space-between;

        .account-btn-right {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #666666;

            i {
                margin-right: 6px;
            }
        }

        .btn-blue {
            width: 96px;
            background: #574DED;
            border-radius: 4px;
            color: #fff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
        }
    }
}

.addAccount-item {
    ::v-deep .el-dialog__body {
        padding: 0 20px;
    }
}

.baijai-code {
    width: 200px;
    height: 200px;
    margin: 0 auto;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.code-title {
    text-align: center;
    font-size: 16px;
    color: #ff0000;
    margin-bottom: 10px;
}
