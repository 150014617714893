
.more-platform{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 300px;
    .more-platform-list-scrollbar{
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        ::v-deep .el-scrollbar__view{
            display: flex;
            flex-wrap: wrap;
        }
    }
    .more-platform-wrapper{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .more-platform-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 10%;
            margin-bottom: 22px;
            img{
                width: 50px;
                min-height: 50px;
                margin: 10px 0;
                box-sizing: border-box;
                cursor: pointer;
            }

            .platform-title{
                color: #666;
                font-size: 14px;
            }
        }
        .more-platform-title{
            display: inline-block;
            width: 100%;
        }
    }
}
